$(function () {
  const attachmentSetting = $(".js-attachment-settings");
  if (attachmentSetting.length == 0) { //we don't have a file attachment setting so bail
    return;
  }

  const moment = require('moment');
  const maxAttachments = parseInt(attachmentSetting.data("max-attachments"));

  let currentAttachmentCount = parseInt(attachmentSetting.data("attachment-count"));
  currentAttachmentCount = currentAttachmentCount || 0;

  // Async Attachment Uploads for use in the inspection creation form. Sibling logic to this exists seperately for Tickets due to required differences. See: app-custom16.js.
  $("body").on("click", ".js-remove-attached-file", function (e) {
    e.preventDefault();

    if ($(this).data("attachment-id") !== undefined) {
      $("#" + $(this).data("attachment-id")).val(1);
      $(this).parents(".js-attached-file").hide();
    } else {
      $(this).parents(".js-attached-file").remove();
    }
  });

  $(".js-attachment-upload-field").fileupload({
    type: "POST",
    // NOTE: url and formData are intentionally blank. Should be defined on a
    // per file basis.
    url: "",
    formData: [],
    dataType: "xml",
    // NOTE: Multipart is required to be true in order to use the formData
    // attribute with Blueimp to AWS. var attachmentContainer = $(this).parent(".js-inspection-attachment-form");
    multipart: true,
    sequentialUploads: true,
    autoUpload: true,
    // NOTE: When singleFileUpload opt is true, it will process each file
    // individually, and this will always be a 1 element array.
    // If singleFileUpload was false, it would try to group per selection.
    // This affects both the add and submit callback logic.
    // AWS S3 will throw an error against a batched submit as it does not allow
    // for bulk uploads. S3 error message: "Upload requires exactly one file"
    singleFileUploads: true,
    replaceFileInput: true,

    add: function (e, data) {
      var currentFile = data.files[0]
      // NOTE: Must submit as array, even when 1x element.
      var fileData = [{ name: currentFile.name }]

      // NOTE: Validates that the total files does not exceed the max allowed.
      if (currentAttachmentCount >= maxAttachments) {
        message = "No more than " + maxAttachments + " files allowed!"
        tbAlert(message, 'danger')
        window.alert(message)
      } else {
        currentAttachmentCount += 1

        var url = window.location.protocol + "//" + window.location.hostname + "/api/v4/uploads/presigned_posts"

        $.ajax({
          url: url,
          data: JSON.stringify({ file_data: fileData }),
          type: "post",
          contentType: 'application/json',
          dataType: "json",
          success: function (response) {
            var presignedPost = response.presignedPosts[currentFile.name];

            // NOTE: Set the url and formData for blueimp file upload to unique
            // presigned url per file.
            data.url = presignedPost.url;
            data.formData = [
              // NOTE: Key must be first for use in rebuilding the file's
              // GET URL in the uploaders done() block.
              {
                "name": "key",
                "value": presignedPost.fields["key"]
              },
              {
                "name": "acl",
                "value": presignedPost.fields["acl"]
              },
              {
                "name": "policy",
                "value": presignedPost.fields["policy"]
              },
              {
                "name": "x-amz-algorithm",
                "value": presignedPost.fields["x-amz-algorithm"]
              },
              {
                "name": "x-amz-credential",
                "value": presignedPost.fields["x-amz-credential"]
              },
              {
                "name": "x-amz-date",
                "value": presignedPost.fields["x-amz-date"]
              },
              {
                "name": "x-amz-signature",
                "value": presignedPost.fields["x-amz-signature"]
              }
            ]

            data.process().done(function () {
              data.submit();
            });
          },
          error: function (jqXHR, textStatus, errorThrown) {
            var errorMessages = jqXHR.responseJSON.errors.join(", ")

            window.alert("There was an error uploading your attachment. Please try again. File Name:" + currentFile.name + ". Error Message: " + errorMessages)
          }
        });
      }
    },
    start: function () {
      $(".loading").show();
    },
    done: function (e, data) {
      $(".loading").hide();

      var fileName = data.files[0].name;
      // NOTE: This rebuilds the file's GET URL. I had tried leveraging the
      // object-url we are sending back in the presigned post data, but due to
      // the async behavior of BlueImp this was running into race condition
      // bugs. I tried adjusting the async settings for BlueImp without success.
      // This solution is not clean, but stable. We should revisit when time
      // permits.
      var fileUrl = data.url + "/" + data.formData[0].value;

      var attachmentContainer = $(this).parent(".js-attachment-container");
      var newFileHtmlTemplate = attachmentContainer.find(".js-attached-file").last();
      var newFileHtml = $(newFileHtmlTemplate.clone());

      // Only set created_at field if present on the attachment
      const $createdAtField = newFileHtml.find(".js-attachment-created-at-field");
      if ($createdAtField.length == 1) {
        $createdAtField.val(moment().format());
      }

      const $temporaryUrlField = newFileHtml.find(".js-attachment-temporary-url-field");
      $temporaryUrlField.val(fileUrl);

      newFileHtml.find(".js-attachment-filename").text(fileName);

      // Display file in UI and enable field to be included in form submission
      newFileHtml.find('input').prop("disabled", false); 
      newFileHtmlTemplate.after(newFileHtml);
      newFileHtml.show();
    },
    fail: function (e, data) {
      $(".loading").hide();

      var message = $(data._response.jqXHR.responseXML).find("Error Message").text();
      alert("Attachment Failed: " + message);
    }
  });
});
